.app-icon-prefix,
.app-icon-centered {
  margin-right: 1.5rem;
  @include square(1.9rem);
  flex-shrink: 0;
}
.app-icon-prefix {
  margin-right: 1.5rem;
}
.app-icon-centered {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.app-icon-button-wrap {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
}

.app-icon-button {
  align-items: center;
  background: map-get($colors, 'inputBackground');
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include square(4rem);

  &:active {
    transform: scale(0.98);
  }

  svg {
    fill: map-get($colors, 'placeholder');
    @include square(2rem);
  }

  &:hover {
    color: white;
    background-color: map-get($colors, 'primary');

    svg {
      fill: white;
      stroke: white;
    }
  }

  &-text-only {
    font-weight: bold;
    color: map-get($colors, 'placeholder');

    &:hover {
      color: white;
    }
  }

  &-text {
    font-size: 1.6rem;
    font-weight: 700;
    margin-left: 1rem;
  }

  &-primary {
    background-color: map-get($colors, 'primary');
    color: white;

    svg {
      fill: white;
      stroke: white;
    }
  }

  &[disabled][disabled] {
    cursor: auto;
    opacity: 0.5;

    &:hover {
      color: map-get($colors, 'placeholder');
      background-color: map-get($colors, 'inputBackground');

      svg {
        fill: map-get($colors, 'placeholder');
        stroke: map-get($colors, 'placeholder');
      }
    }
  }

  &-sm {
    @include square(3.5rem);

    svg {
      @include square(1.8rem);
    }
  }

  @include media-breakpoint-up(xxl) {
    @include square(5rem);

    svg {
      @include square(2.5rem);
    }

    &-sm {
      @include square(4rem);

      svg {
        @include square(2rem);
      }
    }

    &-text {
      font-size: 1.7rem;
    }
  }

  @include media-breakpoint-up(fullHD) {
    &-text {
      font-size: 1.8rem;
    }
  }

  &-wide-sm {
    width: auto;
    padding: 0 1.5rem;
    border-radius: 10rem;
  }
}
