.app-registration-progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 60px;

  .app-registration-progress-step {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    flex-basis: 150px;

    .app-registration-progress-no {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #e2eaf3;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: map-get($colors, 'primary');
    }

    .app-registration-progress-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
    }

    &.app-registration-progress-step-active {
      .app-registration-progress-no {
        color: #fff;
        background-color: map-get($colors, 'primary');
      }

      .app-registration-progress-title {
        font-weight: 600;
        color: map-get($colors, 'primary');
      }
    }
  }

  .app-registration-progress-separator {
    width: 52px;
    height: 25px;
    flex-shrink: 0;
    display: none;
  }

  .app-registration-progress-separator-l {
    background-image: url(/assets/images/progress-separator-l.png);
    background-position: top left;
    background-size: auto;
    background-repeat: no-repeat;
  }

  .app-registration-progress-separator-r {
    background-image: url(/assets/images/progress-separator-r.png);
    background-position: top right;
    background-size: auto;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-up(s) {
    align-items: center;

    .app-registration-progress-step {
      flex-basis: unset;
    }
    .app-registration-progress-separator {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    .app-registration-progress-separator {
      width: 118px;
    }
  }
}
