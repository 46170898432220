.app-klub-faq-list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;

  @include media-breakpoint-up(lg) {
    gap: 80px;
  }
}

.app-klub-faq-question-group {
  @include klub-borderbox(0.2);
  @include klub-shadowbox;
  background-color: #fff;
  width: 100%;

  .app-klub-faq-question-group-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 40px 20px;

    .app-klub-faq-question-group-code {
      font-weight: 700;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.25px;
      color: map-get($colors, 'primary');
      flex-shrink: 0;
    }
    .app-klub-faq-question-group-name {
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      color: map-get($colors, 'primary');
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      gap: 50px;
      padding: 50px 60px;

      .app-klub-faq-question-group-code {
        font-size: 48px;
      }
      .app-klub-faq-question-group-name {
        font-size: 36px;
      }
    }
  }

  .app-klub-faq-question {
    border-top: 1px solid rgba(140, 147, 165, 0.2);
    padding: 25px 20px;

    .app-klub-faq-question-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      h3 {
        font-size: 1.7rem;
      }

      button {
        flex-shrink: 0;

        .toggle-icon {
          transition: all 0.11s linear;
        }
        .toggle-icon-expanded {
          transform: rotate(180deg);
        }
      }
    }

    .app-klub-faq-question-answer {
      padding-top: 25px;

      /*
      .app-article-text-container {
        color: #000;
      }
      */
    }

    .app-panel-expansible {
      max-height: 1800px; /* TMP */
      &.app-panel-collapsed {
        max-height: 0px;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 25px 60px;

      .app-klub-faq-question-header {
        h3 {
          font-size: 2.2rem;
        }
      }
    }
  }
}
