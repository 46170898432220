.app-datetime {
  align-items: center;
  background-color: map-get($colors, 'inputBackground');
  border-radius: 5px;
  border: none;
  display: flex;
  font-family: $app-font-secondary;
  justify-self: flex-start;
  outline: none;
  width: 100%;
  @include common-control;
  @include input-horizontal-padding();
  .mat-mdc-text-field-wrapper {
    align-items: stretch;
    display: flex;
    height: 100%;
    margin: 0 !important;
    padding: 0;
    width: 100%;
  }
  .mat-mdc-form-field-flex {
    align-items: center;
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-mdc-icon-button {
    width: 40px;
    background-color: transparent;
  }
  .mdc-notched-outline * {
    border: none !important;
  }
}

.mat-calendar-table-header th {
  font-weight: bold !important;
}
.mat-datepicker-content {
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
  background-color: #fff !important;
  border-radius: 4px !important;

  .mat-calendar {
    height: 374px !important;
  }
}
.mat-calendar-body-cell-content {
  border-color: transparent !important;
}
.mat-calendar-body-selected {
  background-color: map-get($colors, 'primary') !important;
  color: #fff !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: map-get($colors, 'inputBorder') !important;
}
.cdk-visually-hidden {
  display: none;
  visibility: hidden;
}
