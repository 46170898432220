.app-header-placeholder {
  width: 100%;
  height: 88px;
}

.header-wrapper {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.app-header {
  width: 100%;
  //height: 88px;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  &.nav-visible {
    position: fixed;
    z-index: 2;
  }

  .app-header-logo {
    position: relative;
    z-index: 10;
    img {
      height: 45px;
      width: auto;
    }
  }

  .app-header-login {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    .app-header-username {
      font-weight: 700;
    }
  }

  .app-header-desktop-only {
    display: none;
  }

  .app-header-burger {
    background: transparent;
    display: block;
    margin: 0;
    padding: 0.5rem;
    position: relative;
    z-index: 10;

    &-icon {
      stroke: map-get($colors, 'primary');
      //stroke: currentColor;
      @include square(3rem);

      &-close {
        fill: map-get($colors, 'grayColor');
        transform: rotate(45deg);
      }
    }
  }

  .app-header-mobile-menu-scroll-sticker {
    display: block;
    position: fixed;
    right: -100%;
    top: 0;
    transition: right 0.2s linear;
    height: 8rem;
    width: 100%;
    background-color: white;
    z-index: 9;

    &.nav-visible {
      right: 0;
    }
  }

  .app-header-mobile-menu {
    background-color: white;
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;
    right: -100%;
    overflow: auto;
    padding: 11rem 1rem 1rem;
    position: fixed;
    top: 0;
    transition: right 0.2s linear;
    width: 100%;
    z-index: 8;

    &.nav-visible {
      right: 0;
    }

    .app-header-menu-item {
      margin: 1rem 0;
      min-width: 20rem;

      &-badge {
        transform: translate(0, 0);
      }
    }

    .app-header-menu-link {
      display: block;
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .app-header-placeholder {
    display: none;
  }
  .app-header {
    //height: 170px;
    padding: 2rem 0; /*160*/

    &.nav-visible {
      position: static;
    }

    .app-header-logo {
      img {
        height: 65px;
      }
    }

    .app-header-desktop-only {
      flex-grow: 1;
      display: block;
    }

    .app-header-burger {
      display: none;
    }

    .app-header-mobile-menu-scroll-sticker {
      display: none;
    }

    .app-header-mobile-menu {
      display: none;
    }

    /*
    .app-header-mobile-menu {
      align-items: center;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
  
      .app-header-menu-item {
        background: transparent;
        display: flex;
        font-weight: 700;
        justify-content: center;
        margin: 0;
        padding: 0 1.6rem;
        position: relative;
  
        &-icon {
          align-items: center;
          display: flex;
          position: relative;
  
          svg {
            @include square(2.6rem);
          }
        }
      }
    }
    */
  }
}
