@mixin cms-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-items: center;
  text-align: center;
}

@mixin cms-borderbox($opacity) {
  border: 1px solid rgba(140, 147, 165, $opacity);
  // margin-top: 2 * $spacer;
  // margin-bottom: 2 * $spacer;
  padding: 2 * $spacer;
}

@mixin cms-shadowbox {
  box-shadow: 0px 12px 14px rgba(0, 73, 150, 0.1);
  border-radius: 12px;
}

.app-article-text-container {
  a {
    cursor: pointer;
    text-decoration: underline;
    color: map-get($colors, 'saleColor');
  }

  ul {
    list-style-type: disc;
    margin: $spacer 0;
    padding-left: 1.5 * $spacer;

    li {
      margin: $spacer;
    }
  }

  ol {
    margin: $spacer 0;
    padding-left: 1.5 * $spacer;

    li {
      margin: $spacer;
    }
  }

  /* ol.lower-alpha */
  ol.lower-alpha {
    list-style-type: lower-alpha;
  }

  /* Table */

  table {
    border-collapse: collapse;
    text-align: left;
    //width: 100%;

    td,
    th {
      padding: 8px 16px;
      border-left: 1px solid rgba(140, 147, 165, 0.4);
      border-right: 1px solid rgba(140, 147, 165, 0.4);
      word-break: normal;
    }

    tr {
      td:first-child,
      th:first-child {
        padding-left: 0;
        border-left: 0;
      }
      td:last-child,
      th:last-child {
        padding-right: 0;
        border-right: 0;
      }
    }
  }

  /* Paragraph Layout */

  .layout {
    display: flex;
    flex-wrap: wrap;

    .layout__region {
      flex: 0 1 100%;
      // margin-top: 2 * $spacer;
      // margin-bottom: 2 * $spacer;
    }
  }

  .layout--layout_threecol_33_34_33 {
    .layout__region {
      // margin-top: 2 * $spacer;
      // margin-bottom: 2 * $spacer;
    }

    @include media-breakpoint-up(sm) {
      .layout__region--first,
      .layout__region--second,
      .layout__region--third {
        flex: 0 1 33.33%;
      }
    }
  }

  .layout--layout_threecol_33_34_33.icons {
    img {
      width: 60px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
    }

    .layout__region--first,
    .layout__region--second,
    .layout__region--third {
      @include klub-borderbox(0.2);
      @include klub-shadowbox;
      background: #fff;
      // padding: 2 * $spacer;
      // margin-top: 2 * $spacer;
      // margin-bottom: 2 * $spacer;
    }

    @include media-breakpoint-up(sm) {
      .layout__region--first,
      .layout__region--second,
      .layout__region--third {
        flex: 0 1 30%;
      }

      .layout__region--second {
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }

  .layout--layout_twocol {
    @include media-breakpoint-up(sm) {
      gap: 2 * $spacer;
      align-items: center;

      .layout__region--first,
      .layout__region--second {
        flex: 1 1 0%;
      }
    }
  }

  .center {
    @include cms-center;
  }

  .light {
    @include rounded-box;

    background-color: rgba(228, 223, 218, 0.6);
    width: 100%;

    h2 {
      color: inherit;
    }
  }

  .border {
    @include cms-borderbox(0.2);
    @include cms-shadowbox;
    @include cms-center;

    flex: 1 1 25%;

    background: #fff;
  }

  .text_border {
    @include cms-borderbox(0.2);
    @include cms-shadowbox;

    flex: 1 1 25%;

    background: #fff;
  }

  /* justified */

  .justified {
    margin-top: $spacer;
    align-items: normal;
    text-align: justify;
    gap: 2%;

    .layout__region--first {
      background-color: #d9d9d9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 1 49%;
    }

    .layout__region--second {
      flex: 0 1 49%;

      p {
        margin: 0 0 $spacer 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  /* gallery */
  .gallery .layout__region--content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 3rem;
    /* margin: 2rem 0; */
  }
}

.icons .layout__region {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2 * $spacer;
  margin: 0;

  .paragraph--icon {
    @include cms-borderbox(0.2);
    @include cms-shadowbox;
    @include cms-center;

    margin: 0;

    flex: 1 1 25%;
    flex-basis: 30rem;
    flex-grow: 0;
    justify-content: flex-start;

    gap: 2 * $spacer;

    background: #fff;

    img {
      width: 60px;
      height: 60px;
    }

    p {
      margin: $spacer 0;
    }
  }

  @include media-breakpoint-up(lg) {
    gap: 3 * $spacer;
  }
}

.app-registration-article {
  margin-top: 10px;
  padding-left: 20px;
  border-left: 1px solid rgba(140, 147, 165, 0.4);

  .app-article-title {
    text-align: left;
    font-size: 24px;
    line-height: 32px;
  }
}
