// TODO: encapsulate close btn in angular component
.app-dialog-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 1.3rem;
  position: absolute;
  right: 0;
  top: 0.5rem;
  z-index: 50;

  &__icon {
    fill: map-get($colors, 'primary');
    stroke: map-get($colors, 'primary');
    transform: rotate(45deg);
    @include square(2.5rem); /* changed with klub (was 1.4rem on eshop, 3rem on club) */
  }

  &:focus {
    outline: 0;
  }

  @include media-breakpoint-up(md) {
    right: 0.5rem;
    top: 0.5rem;
  }

  @include media-breakpoint-up(lg) {
    right: 1rem;
    top: 1rem;
  }
}
// EOF TODO: encapsulate close btn in angular component

.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    box-shadow: none !important;

    @include media-breakpoint-up(md) {
      @include app-border-radius(1rem);
    }

    @include media-breakpoint-up(lg) {
      @include app-border-radius(2rem);
    }
  }
}

.cdk-overlay-pane.app-dialog--detail {
  width: 100%;
  height: 100%;

  .mat-mdc-dialog-container {
    overflow: hidden;
    height: unset !important;
  }

  @include media-breakpoint-up(md) {
    padding: 2rem 0;
    width: 75rem;
  }

  @include media-breakpoint-up(lg) {
    width: 100rem;
  }

  @include media-breakpoint-up(xxl) {
    width: 115rem;
  }
}

.app-dialog-auto-height.cdk-overlay-pane {
  height: auto;
}

.app-dialog-padding.cdk-overlay-pane {
  .mat-mdc-dialog-surface {
    padding: 24px;
  }
}

.app-dialog-detail-content {
  height: 100%;
  overflow: auto;
  padding: 3rem 1rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 100%;
    padding: 5rem 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 5rem 4rem;
  }
}

.app-dialog-content {
  margin: 3rem 0;
}

.app-dialog-text {
  max-width: 60rem;
}

.app-dialog-actions {
  align-items: center;
  display: flex;
  justify-content: center;
}

// app-dialog--shop
.cdk-overlay-pane.app-dialog--shop {
  @include media-breakpoint-up(md) {
    width: unset;
    height: unset;
    max-height: 90vh;
    width: 70rem;
  }

  @include media-breakpoint-up(lg) {
    width: 75rem;
  }

  @include media-breakpoint-up(xxl) {
    width: 75rem;
  }
}

// app-dialog--product
.cdk-overlay-pane.app-dialog--product {
  @include media-breakpoint-up(md) {
    width: unset;
    height: unset;
    max-height: 90vh;
    width: 65rem;
  }

  @include media-breakpoint-up(lg) {
    width: 90rem;
  }

  @include media-breakpoint-up(xxl) {
    width: 100rem;
  }
}
