.app-header-menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  a {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    color: map-get($colors, 'primary');
    text-align: center;
    margin: 4px;
    padding: 6px;
  }
  .app-submenu-expansible a {
    font-weight: normal;
  }
}
.app-header-desktop-menu-items {
  flex-direction: row;
  flex-wrap: wrap;
}
.app-side-menu-items {
  display: none;
}

.app-mat-menu {
  background-color: white !important;
  border-radius: 5px !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 1em 1em !important;
  min-width: 200px !important;
  max-width: 400px !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }

  a {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    color: map-get($colors, 'primary');
    text-align: center;
    margin: 4px;
    padding: 6px;
  }

  .mat-mdc-menu-panel {
    max-width: 40rem;
  }
}

@include media-breakpoint-up(lg) {
  .app-header-menu-items {
    margin-bottom: 0;
  }
  .app-side-menu-items {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    gap: 16px;
    width: 300px;
    flex-grow: 0;
    flex-shrink: 0;

    a {
      box-sizing: border-box;
      padding: 16px;

      background: #fff;
      border-bottom: 1px solid #e2eaf3;
      box-shadow: 0px 4px 14px rgba(0, 73, 150, 0.08);
      border-radius: 12px;

      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: map-get($colors, 'primary');

      transition: all 0.11s linear;

      &:hover {
        background-color: map-get($colors, 'primary');
        color: white;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .app-header-menu-items,
  .app-mat-menu {
    a {
      font-size: 1.5rem;
    }
  }
}
