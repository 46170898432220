// project specific
@mixin klub-borderbox($opacity) {
  border: 1px solid rgba(140, 147, 165, $opacity);
}
@mixin klub-shadowbox {
  box-shadow: 0px 12px 14px rgba(0, 73, 150, 0.1);
  border-radius: 12px;
}
@mixin klub-reset-bordershadowbox {
  border: unset;
  box-shadow: unset;
  border-radius: unset;
}

@mixin rounded-box {
  text-align: center;
  padding: $spacer;
  margin-top: $spacer;
  margin-bottom: $spacer;

  border-radius: 1rem;

  h2 {
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 2 * $spacer;
    margin-top: 2 * $spacer;
    margin-bottom: 2 * $spacer;

    h2 {
      margin: 0;
    }
  }
}
