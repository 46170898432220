.reg-card-img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 400px;
  width: 100%;
  @include media-breakpoint-up(md) {
    flex-basis: 400px;
  }
}

.reg-card-banner {
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 250px;
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 250px;
  }
  @include media-breakpoint-up(md) {
    flex-basis: 300px;
  }
}

.tmp-additional-margin-mobile-2 {
  margin-top: 0.5rem;
}
@include media-breakpoint-down(sm) {
  .tmp-additional-margin-mobile-1 {
    margin-top: 30px;
  }
  .tmp-additional-margin-mobile-2 {
    margin-top: 40px;
  }
}
