.app-klub-separator {
  height: 26px;
  width: auto;
  margin: $spacer 0;

  @include media-breakpoint-up(lg) {
    height: 46px;
    margin: 2 * $spacer 0;
  }
}
