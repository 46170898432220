/* TEST ONLY */
/*
h1, h2, h3, h4 {
  text-decoration: line-through;
  // color: map-get($colors, 'primary');
  border: 3px solid $test-lib-color1;
  //background-color: map-get($test-lib-colors, 'test-lib1');
  border-bottom: 3px solid map-get($test-lib-colors, 'test-lib1');
}
*/
