.app-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  width: 100%;
  max-width: 428;
  padding: 10px;

  .app-message-icon {
    width: 40px;
    height: 40px;
    flex-grow: 0;
  }

  .app-message-content {
    color: map-get($colors, 'primary');
    display: flex;
    flex-direction: column;
    gap: 16px;

    .app-message-title {
      margin-top: 0;
      margin-bottom: 0;
    }

    .app-message-subcontent {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
    }
    .app-message-subcontent:empty {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .app-message {
    gap: 24px;
  }
}
