.app-tooltip {
  position: relative;

  &-icon {
    @include square(1.9rem);
  }
}
.app-help-tooltip {
  background: white;
  display: block;
  padding: 0 2rem 0 2rem;
  position: absolute;
  right: -5.5rem;
  top: 50%;
  transform: translateY(-50%);

  &-icon {
    display: block;
    fill: map-get($colors, 'primary');
    @include square(1.6rem);
  }
}

.app-tooltip,
.app-help-tooltip {
  &--input-label {
    padding: 0;
    right: -1.5rem;
  }

  &-text {
    background-color: white;
    border: 1px solid map-get($colors, 'inputBorder');
    border-radius: 5px;
    color: map-get($colors, 'mineShaft');
    display: none;
    left: 50%;
    padding: 1.6rem 1rem;
    position: absolute;
    top: -2rem;
    transform: translate(-50%, -100%);
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    width: 30rem;

    &:after {
      bottom: -1rem;
      background-color: white;
      border: 1px solid map-get($colors, 'inputBorder');
      border-bottom: none;
      border-left: none;
      content: '';
      left: 50%;
      position: absolute;
      transform: translateX(-50%) rotate(135deg) skew(-10deg, -10deg);
      z-index: -1;
      @include square(2rem);
    }
  }

  &:hover .app-tooltip-text,
  &:hover .app-help-tooltip-text {
    display: block;
    position: absolute;
  }
}
