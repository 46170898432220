// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import 'app-variables'; // import and override common variables

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/variables-dark';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import 'node_modules/bootstrap/scss/utilities';
// @import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
@import 'node_modules/bootstrap/scss/containers';
@import 'node_modules/bootstrap/scss/grid';
// @import "node_modules/bootstrap/scss/helpers";
@import 'node_modules/bootstrap/scss/spinners';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import 'node_modules/bootstrap/scss/utilities/api';

// 8. Add additional custom code here

// Common
@import 'projects/coop-web-common/src/styles/common-styles'; // import common styles
@import 'projects/coop-web-common/src/styles/common-components'; // import common components styles

// Project
@import 'app-mixins'; // project mixins
@import 'app-typo';
@import 'app-layout';

/* CSS COMPONENTS - TMP - TODO: REFACTOR, MERGE */
@import 'components-tmp/app-konto-tmp';
@import 'components-tmp/app-button';
@import 'components-tmp/app-radio';
@import 'components-tmp/app-checkbox';
@import 'components-tmp/app-address-search-bar';
@import 'components-tmp/app-input';
@import 'components-tmp/app-field';
@import 'components-tmp/app-datetime';
@import 'components-tmp/app-tooltip';
//@import "components-tmp/app-dialog";
@import 'components-tmp/app-custom-scrollbar-container';
@import 'components-tmp/app-cookies';

/* CSS COMPONENTS */
@import 'components/app-klub-input';
@import 'components/app-header';
@import 'components/app-footer';
@import 'components/app-panel-expansible';
@import 'components/app-flex-table-override';
@import 'components/app-menu';
@import 'components/app-klub-box';
@import 'components/app-klub-separator';
@import 'components/app-klub-home';
// @import "components/app-article";
@import 'components/app-cms-article';
@import 'components/app-registration';
@import 'components/app-registration-progress';
@import 'components/app-klub-action-button';
@import 'components/app-klub-user';
@import 'components/app-klub-store-list';
@import 'components/app-klub-partners';
@import 'components/app-klub-faq';

@import 'components/app-cms';

/*
@import "components/app-header-logo";
@import "components/app-badge";
@import "components/app-input";
@import "components/app-field";
@import "components/app-filter";
@import "components/app-select";
@import "components/app-textarea";
@import "components/app-checkbox";
@import "components/app-datetime";
@import "components/app-detail";
@import "components/app-dropdown";
@import "components/app-rectangle-icon-button";
@import "components/app-back-link";
@import "components/app-dropzone";
@import "components/app-navigation-bar";
@import "components/app-table";
@import "components/app-list";
@import "components/app-category";
@import "components/app-product";
@import "components/app-product-detail";
@import "components/app-tipbox";
@import "components/app-slot";
@import "components/app-dialog";
@import "components/app-infobox";
@import "components/app-promotion";
@import "components/app-transport-service";
*/
