/* this version is fixed - use this when moving to common, but check web and cms version also */

.app-button {
  align-items: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  @include app-border-radius(2.736em);
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  height: 2.736em;
  justify-content: center;
  min-width: 5rem;
  padding: 0 1.5em !important;
  transition: all 0.11s linear;

  &[disabled][disabled] {
    cursor: auto;
    opacity: 0.5;
  }

  &:active {
    transform: scale(0.98);
  }

  &-primary {
    background-color: map-get($colors, 'primary');
    border: 1px solid map-get($colors, 'primary');
    color: white;

    &:hover {
      background-color: white;
      color: map-get($colors, 'primary');
    }

    &[disabled][disabled] {
      &:hover {
        background-color: map-get($colors, 'primary');
        border: none;
        color: white;
      }
    }
  }

  &-secondary {
    background-color: white;
    border: 1px solid map-get($colors, 'primary');
    box-shadow: none;
    color: map-get($colors, 'primary');

    &:hover {
      background-color: map-get($colors, 'primary');
      border-color: map-get($colors, 'primary');
      color: white;
    }

    &[disabled][disabled] {
      &:hover {
        background-color: white;
        border-color: map-get($colors, 'primary');
        color: map-get($colors, 'primary');
      }
    }
  }

  &-important {
    background-color: map-get($colors, 'saleColor');
    border: 1px solid map-get($colors, 'saleColor');
    color: white;

    &:hover {
      background-color: white;
      color: map-get($colors, 'saleColor');
    }

    &[disabled][disabled] {
      &:hover {
        background-color: map-get($colors, 'saleColor');
        border: none;
        color: white;
      }
    }
  }

  &-sm {
    @include app-border-radius(2.2914em);
    font-size: 1.4rem;
    height: 2.2914em;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.7rem;
    min-width: 15rem;

    &-sm {
      font-size: 1.5rem;
    }
  }
}

.app-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
