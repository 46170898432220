.dark {
  @include rounded-box;

  color: white;
  background-color: map-get($colors, 'primary');

  h2 {
    color: inherit;
  }
}

.bg-coop-gray {
  background-color: #d9d9d9; //TODO variable
}

.light-box {
  @include rounded-box;

  background-color: rgba(228, 223, 218, 0.6);

  h2 {
    color: inherit;
  }
}

.white-box {
  @include rounded-box;

  color: $black;
  background-color: white; //map-get($colors, 'white');
  text-align: unset;
  //padding: 4rem;

  box-shadow: 0px 12px 14px rgba(0, 73, 150, 0.1);
}

.coop-article-centered-container {
  .coop-article-border-box {
    color: $black;
    gap: $spacer;

    h3 {
      color: map-get($colors, 'primary');
    }
  }
}

/* TODO CMS styles - only for development */

.cms-article-text-container {
  //color: map-get($colors, 'primary');

  /* Typography */
  p {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  ul {
    list-style-type: disc;
    margin: 0;
    padding: 2 * $spacer;
    padding-left: 4 * $spacer;

    li {
      margin: $spacer;
    }
  }

  /* Table */

  table {
    border-collapse: collapse;
    text-align: left;
    //width: 100%;

    td,
    th {
      padding: 8px 16px;
      border-left: 1px solid rgba(140, 147, 165, 0.4);
      border-right: 1px solid rgba(140, 147, 165, 0.4);
      word-break: normal;
    }

    tr {
      td:first-child,
      th:first-child {
        padding-left: 0;
        border-left: 0;
      }
      td:last-child,
      th:last-child {
        padding-right: 0;
        border-right: 0;
      }
    }
  }

  /* Paragraph Layout */

  .layout {
    display: flex;
    flex-wrap: wrap;

    .layout__region {
      flex: 0 1 100%;
      margin-top: $spacer;
      margin-bottom: $spacer;
    }
  }

  .layout--layout_threecol_33_34_33 {
    .layout__region {
      margin-top: $spacer;
      margin-bottom: $spacer;
    }

    @include media-breakpoint-up(sm) {
      .layout__region--first,
      .layout__region--second,
      .layout__region--third {
        flex: 0 1 33.33%;
      }
    }
  }

  .layout--layout_threecol_33_34_33.icons {
    img {
      width: 60px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
    }

    .layout__region--first,
    .layout__region--second,
    .layout__region--third {
      @include klub-borderbox(0.2);
      @include klub-shadowbox;
      background: #fff;
      padding: 2 * $spacer;
      margin-top: 2 * $spacer;
      margin-bottom: 2 * $spacer;
    }

    @include media-breakpoint-up(sm) {
      .layout__region--first,
      .layout__region--second,
      .layout__region--third {
        flex: 0 1 30%;
      }

      .layout__region--second {
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }

  .layout--layout_twocol {
    @include media-breakpoint-up(md) {
      gap: 2%;
      align-items: center;

      .layout__region--first,
      .layout__region--second {
        flex: 0 1 49%;
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .light {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 3 * $spacer;
    // margin-top: 4 * $spacer;
    // margin-bottom: 4 * $spacer;

    background-color: rgba(228, 223, 218, 0.6);
    border-radius: 12px;

    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    text-align: center;
    // color: map-get($colors, 'primary');
    /*
    @include media-breakpoint-up(lg) {
      h2 {
        font-size: 40px;
        line-height: 53px;
      }
    }
    */
  }

  .border {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: $spacer;
    margin-bottom: $spacer;
    padding: 30px;
    // gap: 30px;
    flex: 1 1 25%;
    text-align: center;

    background: #fff;
    @include klub-borderbox(0.2);
    @include klub-shadowbox;

    img {
      // width: 60px;
      // height: 60px;
    }
  }
}
