.app-custom-scrollbar-container {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: map-get($colors, 'primary');
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: map-get($colors, 'primary');
  }
}
