/* readonly: color changed, placeholder hidden */
.app-input {
  margin-bottom: 2rem;

  &-label {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
  }

  .mat-mdc-form-field {
    width: 100%;
  }
  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  &.app-input-readonly {
    input {
      color: map-get($colors, 'placeholder');

      &::placeholder {
        opacity: 0;
      }
    }
  }
}
.app-input-error {
  color: map-get($colors, 'warn') !important;
  display: block;
  font-size: 1.5rem;
  margin-top: 0.4em;
}
.app-input-help {
  display: none;
}

@include media-breakpoint-up(md) {
  .app-input-help {
    align-items: center;
    cursor: auto;
    display: flex;
    margin-left: 0.5rem;
    position: relative;

    &:hover .app-input-help-box {
      display: block;
    }

    &-icon {
      fill: map-get($colors, 'primary');
      @include square(1.4rem);
    }
  }
  .app-input-help-box {
    background-color: white;
    border: 1px solid map-get($colors, 'inputBorder');
    border-radius: 5px;
    color: map-get($colors, 'mineShaft');
    display: none;
    left: 50%;
    padding: 1.6rem 1rem;
    position: absolute;
    top: -2rem;
    transform: translate(-50%, -100%);
    font-weight: 400;
    text-align: center;
    width: 30rem;

    &:after {
      bottom: -1rem;
      background-color: white;
      border: 1px solid map-get($colors, 'inputBorder');
      border-bottom: none;
      border-left: none;
      content: '';
      left: 50%;
      position: absolute;
      transform: translateX(-50%) rotate(135deg) skew(-10deg, -10deg);
      @include square(2rem);
    }
  }
}

@include media-breakpoint-up(xxl) {
  .app-input {
    margin-bottom: 2.4rem;
  }

  .app-input-label {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
}

@include media-breakpoint-up(fullHD) {
  .app-input-label {
    font-size: 1.7rem;
  }
}

.app-input-small {
  .app-field-container,
  .app-textarea,
  .app-datetime,
  .app-select {
    width: 20rem;
  }
}
.app-input-medium {
  .app-field-container,
  .app-textarea,
  .app-datetime,
  .app-select {
    width: 40rem;
  }
}
