.app-footer {
  // TMP
  background: #121e31;
  color: #fff;
  padding: 2 * $spacer 0;
  margin-top: 2 * $spacer;

  .footer-logo {
    margin: 1rem 0 2rem 0;
  }

  h2 {
    color: inherit;
    margin: 2rem 0;
  }

  .menu {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      margin-top: 1rem;
      list-style-type: none;
    }
  }

  .contact {
    td {
      padding: 0.5rem;
      vertical-align: top;
    }
  }

  .social {
    float: left;
  }

  .copyright {
    border-top: 1px solid #3d3f4e;
    padding-top: 2rem;
  }
}
