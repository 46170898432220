.app-klub-store-search-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  gap: 40px;
  background: #eae6e2;
  border-radius: 12px;
  flex-grow: 0;
  width: 100%;
}
@include media-breakpoint-up(lg) {
  .app-klub-store-search-box {
    padding: 60px 60px;
  }
}

.app-klub-store-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin: 40px 0;

  .app-klub-store-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 20px;

    width: 387px;
    height: 334px;
    max-width: 90vw;

    background: #fff;
    @include klub-borderbox(0.2);
    @include klub-shadowbox;

    flex-grow: 0;

    text-align: center;

    .store-image {
      width: 157px;
      height: auto;
    }

    .store-name,
    .store-phone {
      font-weight: 700;
    }

    &.app-klub-store-list-item-selected {
      border: 2px solid map-get($colors, 'primary');
    }
  }

  .app-klub-store-list-item-simple {
    width: 300px;
    height: 230px;
    max-width: 70vw;
  }
}
