.gwt-SuggestBoxPopup,
.smartform-company-SuggestBoxPopup {
  border: none !important;
  @include app-border-radius(20px);
  @include app-box-shadow;
  font-family: $app-font-secondary;
  overflow: hidden !important;
  background-color: white;
  padding: 1rem 0;

  .item {
    padding: 4px 25px 3px !important;
    color: inherit !important;
  }

  .item-selected {
    background-color: #f2f3f5 !important;
  }
}
