/* Typography */
/*
p {
  margin: $spacer 0;

  @include media-breakpoint-up(lg) {
    margin: 1.5 * $spacer 0;
  }
}
*/
h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: map-get($colors, 'primary');
  padding: 18px 0;
}
@include media-breakpoint-up(lg) {
  h1 {
    font-size: 40px;
    line-height: 53px;
  }
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: map-get($colors, 'primary');
  margin: $spacer 0;

  @include media-breakpoint-up(lg) {
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    margin: 2 * $spacer 0;
  }
}
/*
h3 {
  font-weight: 500;
  font-size: 1.4rem;
  color: map-get($colors, 'primary');
  margin: $spacer 0;

  @include media-breakpoint-up(lg) {
    font-size: 1.7rem;
  }
}*/

.app-text-link {
  color: map-get($colors, 'danube');
  text-decoration: underline;
}

.app-title-1 {
  font: 700 3rem/1.333 $app-font-primary;

  @include media-breakpoint-up(fullHD) {
    font-size: 4rem;
  }
}

.app-title-2 {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.2;

  @include media-breakpoint-up(fullHD) {
    font-size: 2.2rem;
  }
}

.app-text-1 {
  font-size: 1.3rem;
  line-height: 1.47;
}

.app-text-2 {
  @include media-breakpoint-up(fullHD) {
    font-size: 1.4rem;
  }
}

.app-text-content {
  ol {
    list-style: decimal;
  }
}

.text-justify {
  text-align: justify;
}

/* Nested  ordered list for terms */

ol > li::marker {
  content: counters(list-item, '.') '. ';
}

ol ol > li::marker {
  content: counters(list-item, '.') '. ';
}

ol {
  margin-left: 1rem;

  li {
    //padding: 0.25rem 1rem;
    margin-top: 1em;
    list-style-position: outside;
  }

  ol {
    li {
      margin-left: 1em;
    }
  }

  ul {
    padding-left: 0;

    li {
      list-style-type: disc;
      list-style-position: outside;
      margin-top: 0.5em;
    }
  }
}
