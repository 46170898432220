.app-klub-partner-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3rem;
  margin: 2rem 0;

  .app-klub-partner-list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
    position: relative;

    flex-basis: 35rem;
    flex-grow: 0;

    background: #fff;
    @include klub-borderbox(0.2);
    @include klub-shadowbox;

    // .partner-image {
    //   width: 240px;
    //   height: 120px;
    //   object-fit: scale-down;
    // }

    .partner-name {
      text-align: center;
      font-weight: 700;
    }

    .partner-badge {
      position: absolute;
      right: 3rem;
      top: 2.5rem;
      z-index: 1;
      padding: 0.5rem 1rem;
      border: 2px solid map-get($colors, 'pinkLight');
      border-radius: 50rem;
      background: map-get($colors, 'saleColor');
      color: white;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
}

.app-klub-partner-detail-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include klub-borderbox(0.4);
  @include klub-shadowbox;

  background-color: #fff;
  max-width: 500px;
  padding: 1rem;
  margin-top: 2rem;

  // img {
  //   width: 400px;
  //   height: 200px;
  //   object-fit: scale-down;
  // }

  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }
}
