/*
  Bypass "Could not find Angular Material core theme." warning.
  Based on: https://github.com/angular/components/blob/main/src/material/core/common-behaviors/common-module.ts#L111
 */
.mat-theme-loaded-marker {
  display: none !important;
}

/* Mat Snackbar */
.mat-mdc-snack-bar-container {
  background-color: white;
  border: 1px solid map-get($colors, 'warn');
  border-radius: 5px;

  &.success {
    border-color: map-get($colors, 'primary');

    .mat-mdc-simple-snack-bar {
      color: map-get($colors, 'primary');
    }
  }
}
.mat-mdc-simple-snack-bar {
  color: map-get($colors, 'warn');
  text-align: center;
}
/* EOF: Mat Snackbar */

/* Mat expansion panel */
.mat-expansion-panel {
  & + & {
    border-top: 1px solid map-get($colors, 'inputBackground');
  }

  &:not([class*='mat-elevation-z']) {
    box-shadow: none !important;
  }

  &-body {
    padding: 0px !important;
  }
}
/* EOF: Mat expansion panel */

/* mat-dialog */
.mat-mdc-dialog-container {
  background-color: transparent;
  border: none;
  padding: 0 !important;
}

/* mat-radio-button */
.mat-mdc-radio-group {
  margin: 0 - 2rem;
}

.mat-mdc-radio-button {
  margin: 1rem 4rem 1rem -1rem !important;

  .mdc-form-field > label {
    cursor: pointer;
    padding-left: 0;
  }

  .mdc-radio {
    @include square(2.4rem !important);
    padding: 10px !important;

    &__background {
      @include square(2.4rem !important);
    }

    &__outer-circle {
      border: 1px solid black !important;
      transition: none !important;
      @include square(100% !important);
    }

    &__inner-circle {
      border-style: none !important;
      opacity: 0 !important;
      transition: none !important;
    }

    &-input {
      position: absolute;
      left: -99999px !important;
    }

    &.mdc-radio--disabled {
      cursor: auto;
      opacity: 0.5;

      & + label {
        cursor: default;
        opacity: 0.5;
      }
    }
  }

  &.mat-mdc-radio-checked {
    .mdc-radio {
      &__outer-circle {
        border-color: map-get($colors, 'primary') !important;
      }
      &__inner-circle {
        background-color: map-get($colors, 'primary') !important;
        left: 0.3rem !important;
        opacity: 1 !important;
        top: 0.3rem !important;
        transform: none !important;
        @include square(1.8rem !important);
      }
    }
  }
}
/* EOF: mat-radio-button */

/* mat-tooltip */
.mdc-tooltip__surface {
  border: solid 1px map-get($colors, 'inputBorder') !important;
  border-radius: 4px !important;
  background-color: white !important;
}
/* EOF: mat-tooltip */
