.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}
.swiper-pagination-bullet-active {
  background: map-get($colors, 'primary');
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}
