.app-radio-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;

  &__item {
    margin: 0 2rem;
    margin-bottom: 1rem;
  }

  &__validation-message {
    margin: 0 2rem;
    width: 100%;
  }
}

.app-radio {
  align-items: center;
  display: flex;
  user-select: none;

  &__input {
    position: absolute;
    left: -99999px;
  }

  &__label {
    position: relative;
    padding-left: 3rem;
    cursor: pointer;

    &:before {
      border: 1px solid black;
      border-radius: 100%;
      content: '';
      left: 0;
      position: absolute;
      top: -0.2rem;
      @include square(2.4rem);
    }

    &:after {
      background: map-get($colors, 'primary');
      content: '';
      position: absolute;
      left: 0.3rem;
      border-radius: 100%;
      transition: all 0.2s ease;
      top: 0.1rem;
      @include square(1.8rem);
    }
  }

  &__input:disabled ~ &__label {
    cursor: auto;
    opacity: 0.5;
  }

  &__input:not(:checked) ~ &__label:after {
    opacity: 0;
    transform: scale(0);
  }

  &__input:checked ~ &__label:before {
    border-color: map-get($colors, 'primary');
  }

  &__input:checked ~ &__label:after {
    opacity: 1;
    transform: scale(1);
  }
}
