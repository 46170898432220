// Muli
@font-face {
  font-family: 'Muli';
  font-display: swap;
  src: url(/assets/fonts/Muli/Muli-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  font-display: swap;
  src: url(/assets/fonts/Muli/Muli-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  font-display: swap;
  src: url(/assets/fonts/Muli/Muli-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

// FilsonSoft (Eshop)
@font-face {
  font-family: 'Filson Soft';
  font-display: swap;
  src:
    url(/assets/fonts/FilsonSoft/FilsonSoft-bold.woff2) format('woff2'),
    url(/assets/fonts/FilsonSoft/FilsonSoft-bold.woff) format('woff');
  font-weight: 700;
}

// GoldplayAlt
@font-face {
  font-family: 'GoldplayAlt';
  font-display: swap;
  src: url(/assets/fonts/GoldplayAlt/Goldplay-Alt-Medium.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'GoldplayAlt';
  font-display: swap;
  src: url(/assets/fonts/GoldplayAlt/Goldplay-Alt-SemiBold.otf) format('opentype');
  font-weight: 700;
}
