.app-color-primary {
  color: map-get($colors, 'primary');
  fill: map-get($colors, 'primary');
}
.app-color-warn {
  color: map-get($colors, 'warn');
  fill: map-get($colors, 'warn');
}
.app-color-grey {
  color: map-get($colors, 'inputBorder');
  fill: map-get($colors, 'inputBorder');
}
.app-color-dark-grey {
  color: map-get($colors, 'prefix');
  fill: map-get($colors, 'prefix');
}
.app-color-success {
  color: map-get($colors, 'success');
  fill: map-get($colors, 'success');
}
.app-color-yellow {
  color: map-get($colors, 'yellowColor');
  fill: map-get($colors, 'yellowColor');
}
.app-color-minor {
  color: map-get($colors, 'prefix');
  fill: map-get($colors, 'prefix');
}
.app-color-black {
  color: black;
  fill: black;
}
.mt-6 {
  margin-top: 4rem !important;
}
.mt-7 {
  margin-top: 5rem !important;
}
.mb-6 {
  margin-bottom: 4rem !important;
}
.app-font-weight-normal {
  font-weight: normal;
}
.app-max-h100vh {
  max-height: 100vh;
}

.g-1 {
  gap: 0.25rem !important;
}
.g-2 {
  gap: 0.5rem !important;
}
.g-3 {
  gap: 1rem !important;
}
.g-4 {
  gap: 1.5rem !important;
}
.g-5 {
  gap: 3rem !important;
}

.cg-1 {
  column-gap: 0.25rem !important;
}
.cg-2 {
  column-gap: 0.5rem !important;
}
.cg-3 {
  column-gap: 1rem !important;
}
.cg-4 {
  column-gap: 1.5rem !important;
}
.cg-5 {
  column-gap: 3rem !important;
}

.rg-1 {
  row-gap: 0.25rem !important;
}
.rg-2 {
  row-gap: 0.5rem !important;
}
.rg-3 {
  row-gap: 1rem !important;
}
.rg-4 {
  row-gap: 1.5rem !important;
}
.rg-5 {
  row-gap: 3rem !important;
}

.app-cursor-pointer {
  cursor: pointer;
}

.rotate-90 {
  transform: rotate(90deg);
}

@include media-breakpoint-up(md) {
  .desktop-absolute-right {
    position: absolute;
    right: 0;
  }
  .desktop-absolute-left {
    position: absolute;
    left: 0;
  }
}
