/* readonly: color changed */
.app-checkbox-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;
}

/* Customize the label (the container) */
.app-checkbox {
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 2.4rem;
  padding-left: 2.4rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &-disabled {
    cursor: initial;
  }

  /* Create a custom checkbox */
  &-checkmark {
    background-color: map-get($colors, 'inputBackground');
    border: 1px solid map-get($colors, 'inputBorder');
    border-radius: 5px;
    left: 0;
    position: absolute;
    top: 0;
    @include square(2.4rem);
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ &-checkmark {
    background-color: #ccc;
  }

  &-disabled:hover input ~ &-checkmark {
    background-color: map-get($colors, 'inputBackground');
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ &-checkmark {
    background-color: map-get($colors, 'primary');
    border-color: map-get($colors, 'primary');
  }
  &-disabled input:checked ~ &-checkmark {
    opacity: 0.5;
  }

  & input:checked ~ &-label .app-dropdown-icon {
    transform: rotate(180deg);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ &-checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & &-checkmark:after {
    left: 7px;
    border-radius: 3px;
    top: 2px;
    width: 9px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.app-checkbox-label {
  margin-left: 1rem;
  align-items: center;
  display: flex;
}
