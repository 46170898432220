.app-panel-expansible {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 0.33s linear;

  &.app-panel-collapsed {
    max-height: 0px;
  }
}
.app-panel-expansible-toggle {
  fill: currentColor;
  @include square(1.2rem);
  transition: transform 0.11s linear;
  margin-left: 0.5rem;
}
.app-panel-toggle-expanded {
  transform: rotate(180deg);
}
