/* CDK */
.cdk {
  position: fixed;
  z-index: 1000;

  &-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-overlay-pane {
    max-width: 100vw !important;
  }
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-overlay-pane {
  pointer-events: auto;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-pane {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    height: auto;
  }
}
.cdk-visually-hidden {
  display: none;
  visibility: hidden;
}
/* EOF: CDK */
