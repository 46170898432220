input,
textarea,
select {
  font-family: 'Muli', Arial, sans-serif;
  font-size: 17px;
  line-height: 21px;
}

.konto-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 428;
}
.konto-input label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 14px;
}
.konto-input input {
  box-sizing: border-box;
  height: 60px;
  padding: 0 30px;
  background: #f7f9fc; /* TODO variable */
  border: 1px solid #8c93a5; /* TODO variable */
  border-radius: 5px;
}
