.app-default-icon {
  display: block;
  @include square(1.8rem); // default size
  flex-shrink: 0;
}

.app-small-icon {
  display: block;
  @include square(1.5rem);
  flex-shrink: 0;
}
