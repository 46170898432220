.app-field-container {
  background-color: map-get($colors, 'inputBackground');
  border-radius: 5px;
  position: relative;

  @include input-horizontal-padding();
}

.app-field {
  align-items: center;
  background-color: map-get($colors, 'inputBackground');
  border: none;
  display: flex;
  font-family: $app-font-secondary;
  font-size: 1.4rem;
  height: 4rem;
  justify-self: flex-start;
  outline: none;
  overflow: hidden;
  width: 100%;

  &-prefix,
  &-suffix {
    display: block;
    word-break: normal;
  }

  &-prefix {
    color: map-get($colors, 'prefix');
  }

  &-prefix-icon {
    fill: map-get($colors, 'prefix');
    @include square(1.6rem);
  }

  &-input {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex: 1;
    height: 100%;
    min-width: 1rem;
    padding: 0;

    &::placeholder {
      color: map-get($colors, 'placeholder');
      opacity: 1;
    }
  }

  &-suffix {
    padding-left: 0.4rem;
  }

  &-with-icon {
    padding-right: 6rem;
  }

  &-icon {
    cursor: pointer;
    display: none;
    height: auto;
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2.8rem;

    &.visible {
      display: block;
    }
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.6rem;
    height: 5rem;

    &-prefix-icon {
      @include square(2rem);
    }
  }
}
