.app-klub-user-card-container {
  min-width: 200px;
  max-width: 400px;
  width: 100%;
  position: relative;

  .app-klub-user-card-img {
    min-width: 200px;
    max-width: 400px;
    width: 100%;
  }
  .app-klub-user-card-ean {
    position: absolute;
    bottom: 7%;
    left: 23%;
    width: 48%;
    height: auto;
  }
  .app-klub-user-card-blank-ean {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 70%;
    height: auto;
  }
  .app-klub-user-card-blank-ean-value {
    position: absolute;
    width: 90%;
    height: 20%;
    top: 72%;
    left: 5%;
    background-color: #fff;
    text-align: center;
    font-family: $app-font-secondary;
    font-size: 2rem;
    font-weight: 600;
  }
}

.app-klub-user-dahboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  a {
    @include klub-borderbox(0.4);
    @include klub-shadowbox;
    background-color: #fff;
    width: 250px;
    height: 200px;
    padding: 32px 24px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: map-get($colors, 'primary');

    transition: all 0.11s linear;

    &:hover {
      background-color: map-get($colors, 'primary');
      color: white;
    }

    * {
      text-align: center;
    }
  }
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;

    a {
      width: 300px;
      height: 280px;
      padding: 40px 40px;

      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
    }
  }
}

.app-klub-user-purchases-discounts-table {
  margin-top: 2rem;
  margin-bottom: 2rem;

  .app-klub-user-purchases-discounts-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid map-get($colors, 'silverColor');

    .app-klub-user-purchases-discounts-label {
      width: 18rem;
      flex-grow: 1;
      flex-shrink: 0;
      margin-right: 1.5rem;
    }
    .app-klub-user-purchases-discounts-value {
      width: 0.5rem;
      min-width: 10rem;
      flex-grow: 1;
      flex-shrink: 0;
      text-align: right;
      color: map-get($colors, 'primary');
      font-weight: 700;
      font-size: 20px;
    }
  }
  .app-klub-user-purchases-discounts-row-highlighted {
    border-bottom: 2px solid map-get($colors, 'primary');
  }
}
