.app-klub-box {
  box-sizing: border-box;
  width: 100%;
  margin: $spacer 0;

  .app-klub-box-title-outer {
    padding-bottom: 16px;
  }

  /*
  .app-klub-box-title-inner {
    color: gold;
  }
  */

  .app-klub-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 32px 24px;
    gap: 40px;
    width: 100%;
    background: #fff;
    @include klub-borderbox(0.4);
    @include klub-shadowbox;
  }
  .app-klub-box-container-align-left {
    align-items: flex-start;
  }
}

@include media-breakpoint-up(lg) {
  .app-klub-box {
    margin: 2 * $spacer 0;

    .app-klub-box-container {
      padding: 3 * $spacer;
    }
  }
}

// TMP:
.tmp-boxnomargin {
  .app-klub-box {
    margin-top: 0;
  }
}
