// ************** LAYOUT

:root {
  font-size: 62.5%;

  body {
    margin: 0;
    // Body font size 16px
    font-size: 1.4rem;
    font-family: $app-font-primary;

    //remove tap blue color on mobile device:
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    @include media-breakpoint-up(fullHD) {
      font-size: 1.6rem;
    }
  }
}

.app-page {
  position: relative;
  z-index: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

// bg
.app-page {
  background-image: url(/assets/images/bg-top.svg);
  background-position: 70% -50px;
  background-size: 1200px auto;
  background-repeat: no-repeat;
}

@include media-breakpoint-up(lg) {
  .app-page {
    background-image: url(/assets/images/bg-top.svg), url(/assets/images/bg-bottom.svg);
    background-position:
      right top,
      left 700px;
    background-size:
      100% auto,
      150% auto;
    background-repeat: no-repeat, no-repeat;
  }
}

.app-page-title {
  text-align: center;
  margin: 3 * $spacer $spacer 0 $spacer;

  @include media-breakpoint-up(lg) {
    margin-top: 4 * $spacer;
  }
}

.app-page-backdrop {
  /* background-color: antiquewhite; */
  margin-top: $spacer;
  padding-top: 35px;
  background: url(/assets/images/bg-content.png) no-repeat center top;
  background-size: 1000px auto;
  @include media-breakpoint-up(lg) {
    margin-top: 2 * $spacer;
    padding-top: 70px;
    background-size: 1900px auto;
  }
}

.app-page-2cols-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 24px 0 24px;
  gap: 40px;
  width: 100%;
}
.app-page-2cols-header-only {
  padding-bottom: 80px;
}
.app-page-2cols {
  display: flex;
  flex-direction: row;
  gap: 80px;
  padding: 80px 24px;

  .app-col-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
@include media-breakpoint-up(lg) {
  .app-page-2cols {
    padding: 100px 8%;
  }
  .app-page-2cols-header {
    padding: 100px 8% 0 8%;
  }
  .app-page-2cols-header-only {
    padding-bottom: 100px;
  }
}

.app-container {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2rem;
}
@include media-breakpoint-up(lg) {
  .app-container {
    padding: 4rem 16%;
  }
}

.app-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-flex-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app-flex-centered2left {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-breakpoint-up(lg) {
    align-items: flex-start;
  }
}
.app-flex-no-shrink {
  flex-shrink: 0;
}
.app-form-gap {
  gap: 40px;
}
.app-form-gap-s {
  gap: 20px;
}

// ************** EOF: LAYOUT

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

// basic scale
svg {
  height: 10px;
  width: 10px;
}

:focus {
  outline: none;
}

.app-link {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}
.app-link-red {
  cursor: pointer;
  color: map-get($colors, 'saleColor');
  text-decoration: underline;
}

// autofill style
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px map-get($colors, 'inputBackground') inset !important;
}

/* CDK */
.cdk {
  &-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-overlay-pane {
    max-width: 90vw !important;
  }
}
