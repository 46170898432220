.app-klub-action-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  @include klub-borderbox(0.2);
  @include klub-shadowbox;
  padding: 25px 20px;
  cursor: pointer;
  transition: all 0.11s linear;
  background-color: white;
  color: map-get($colors, 'primary');

  .app-klub-action-button-text {
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: left;
  }

  .app-klub-action-button-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    svg {
      @include square(1.4rem);
      fill: map-get($colors, 'primary');
      flex-shrink: 0;
    }
  }

  .app-klub-action-button-icon {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    @include square(4rem);
    flex-shrink: 0;

    //background-color: map-get($colors, 'primary');
    background-color: map-get($colors, 'inputBackground');

    svg {
      @include square(2rem);
      //fill: white;
      fill: map-get($colors, 'primary');
    }

    @include media-breakpoint-up(xxl) {
      @include square(5rem);

      svg {
        @include square(2.5rem);
      }
    }
  }

  &:hover {
    background-color: map-get($colors, 'primary');
    color: white;

    .app-klub-action-button-info {
      svg {
        fill: white;
      }
    }

    .app-klub-action-button-icon {
      background-color: map-get($colors, 'inputBackground');

      svg {
        fill: map-get($colors, 'primary');
      }
    }
  }

  &[disabled][disabled] {
    cursor: auto;
    background-color: white;
    color: map-get($colors, 'placeholder');
    //pointer-events: none;

    .app-klub-action-button-info {
      svg {
        fill: map-get($colors, 'placeholder');
      }
    }

    .app-klub-action-button-icon {
      cursor: auto;

      background-color: map-get($colors, 'inputBackground');

      svg {
        fill: map-get($colors, 'placeholder');
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 25px 60px;
  }
}

.app-klub-action-button-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  @include klub-borderbox(0.2);
  @include klub-shadowbox;
  background-color: #fff;
  overflow: hidden;

  &:empty {
    display: none;
  }

  .app-klub-action-button {
    @include klub-reset-bordershadowbox;
  }

  app-klub-action-button {
    border-bottom: 1px solid rgba(140, 147, 165, 0.2);

    &:last-of-type {
      border-bottom: none;
    }
  }
}
