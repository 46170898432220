.search-bar-autocomplete.mdc-menu-surface.mat-mdc-autocomplete-panel {
  max-height: 70vh;
  border-radius: 15px !important;
  background-color: white;
  padding-top: -2rem;
}

.search-bar-autocomplete {
  @include app-box-shadow;
  /* margin-top: 10px; */
  padding-top: 15px !important;
  padding-bottom: 15px !important;

  .mat-mdc-option {
    min-height: unset;
    height: 30px;

    .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .mat-mdc-optgroup {
    display: block;
    /*
    max-height: 120px;
    overflow-x: auto;
    */
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .mat-mdc-option:not(.mat-mdc-option-multiple) {
      padding-left: 25px;
    }
  }

  .mat-mdc-optgroup-label {
    min-height: unset;
    height: 30px;
    line-height: 30px;
    padding-left: 25px;
    font-size: 1.2rem;
  }

  &__title {
    font-size: 1.3rem;
    font-weight: bold;
  }

  background-color: white;

  .mat-mdc-option {
    &:hover {
      background-color: #f2f3f5;
    }
  }

  &__store-count,
  &__showall-option {
    color: map-get($colors, 'danube');
  }

  &__showall-option.mat-mdc-option {
    justify-content: center;

    &:hover {
      text-decoration: underline;
    }
  }
}
