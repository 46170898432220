$app-font-primary: 'GoldplayAlt', Arial, sans-serif;
$app-font-secondary: 'Muli', Arial, sans-serif;

$grid-breakpoints: (
  xs: 0,
  s: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px,
  fullHD: 1920px,
  2k: 2500px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1150px,
);

// tool for naming colors http://chir.ag/projects/name-that-color
$colors: (
  chambray: #3b5998,
  doveGray: #707070,
  pomegranate: #f73f2f,
  tundora: #474747,
  grayColor: #7f7f7f,
  mineShaft: #272727,
  periwinkGray: #dae3f0,
  silverColor: #cccccc,
  danube: #668ecb,
  lightBlueColor: #e1eaf3,
  darkBlueColor: #004c96,
  pinkLight: #f2ddde,
  saleColor: #ed1c24,
  success: #66bc52,
  yellowColor: #ffbb00,

  primary: #004c96,
  primaryLight: #e1f8ee,
  primaryLighter: #effbf7,
  accent: #e8f1ff,
  warn: #e66060,
  warnLight: #fae1e1,
  warnLighter: #fdefef,
  inputBackground: #f7f7f6,
  inputBorder: #a0a0a0,
  placeholder: #8f8f8f,
  prefix: #545454,
);

$theme-colors: (
  'primary': map-get($colors, 'primary'),
  'secondary': map-get($colors, 'accent'),
  'success': map-get($colors, 'primary'),
  'info': map-get($colors, 'placeholder'),
  'warning': map-get($colors, 'warn'),
  'danger': map-get($colors, 'warn'),
  'light': white,
  'dark': black,
);

@mixin app-border-radius($height) {
  border-radius: $height $height $height $height !important;
}

@mixin app-box-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) !important;
}

$padding-h-small: 10px;
$padding-h-medium: 30px;
$padding-h-regular: 80px;
$padding-h-big: 200px;

@mixin horizontal-padding($padding) {
  padding-left: $padding-h-small;
  padding-right: $padding-h-small;

  @include media-breakpoint-up(lg) {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@mixin input-horizontal-padding() {
  padding-left: 1.4rem;
  padding-right: 1.4rem;

  @include media-breakpoint-up(xxl) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-up(fullHD) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin green-background {
  background-color: map-get($colors, 'primary');
  color: white;
  ::selection {
    color: black;
    background: #b4cff8;
    fill: black;
  }
}

@mixin common-control {
  font-size: 1.4rem;
  height: 4rem;

  @include media-breakpoint-up(xxl) {
    font-size: 1.6rem;
    height: 5rem;
  }

  /* was not aligned with app-input
  @include media-breakpoint-up(fullHD) {
    font-size: 1.7rem;
    height: 6rem;
  }
  */
}

@mixin app-max-lines($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
