.app-cookies {
  background: white;
  bottom: 0;
  border: 2px solid map-get($colors, 'primary');
  border-bottom: 0;
  border-radius: 1em 1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.1) 0px -5px 10px;
  color: map-get($colors, 'primary');
  display: none;
  font-size: 13px;
  left: 50%;
  line-height: 1.4;
  max-width: 50rem;
  padding: 1.6em 0.8em;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;

  &__info.active,
  &__settings.active,
  &.active {
    display: block;
  }

  &__info,
  &__settings {
    display: none;
  }

  &__title {
    font-size: 1.3em;
    margin-bottom: 0.5em;
  }

  &__subtitle {
    font-size: 1.1em;
  }

  &__controls {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }

  &__btn {
    max-width: 20rem;

    & + & {
      margin-left: 1em;
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
  }

  &__item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    user-select: none;

    &.active {
      cursor: pointer;
    }

    & + & {
      margin-top: 1em;
    }
  }

  &__typo {
    flex: 1;
    margin-right: 1rem;
  }

  &__check {
    @include square(2rem);
  }

  @include media-breakpoint-up(s) {
    padding: 1em 2em;
  }

  @include media-breakpoint-up('xxl') {
    font-size: 15px;
  }
}
