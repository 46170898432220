.app-home-col,
.app-home-row {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .app-home-col,
  .app-home-row {
    gap: 2 * $spacer;
  }

  .app-home-row {
    flex-direction: row;
    justify-content: stretch;
  }
}

.app-home-box {
  border-radius: 12px;
  position: relative;
  flex-grow: 1;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  h2 {
    position: absolute;
    left: 3rem;
    top: 2rem;
  }

  .text-red {
    color: red;
  }

  button,
  a {
    position: absolute;
    left: 3rem;
    bottom: 2rem;
  }

  a {
    color: white !important;
    text-decoration: none !important;

    &:hover {
      color: map-get($colors, 'primary') !important;
    }
  }
}

.app-home-banner-box {
  border-radius: 12px;
  overflow: hidden;
  margin: $spacer 0;
}
.app-home-banner {
  width: 100%;
  height: auto;
}
@include media-breakpoint-up(lg) {
  .app-home-banner-box {
    margin: 2 * $spacer 0;
  }
}

.app-home-title {
  width: 100%;
  margin-top: 40px;
  padding: 34px;
  background: map-get($colors, 'primary');
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  text-align: center;
}
@include media-breakpoint-up(lg) {
  .app-home-title {
    font-size: 40px;
    line-height: 53px;
    margin-top: 60px;
  }
}

.app-landscape {
  display: none;
}
.app-portrait {
  display: block;
}
@include media-breakpoint-up(sm) {
  .app-landscape {
    display: block;
  }
  .app-portrait {
    display: none;
  }

  .app-home-box.app-login2 button {
    left: unset;
    right: 30px;
  }
}
