.app-checkbox-switch-container {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  gap: 1rem;

  &.disabled {
    cursor: auto;
  }
}

/* The switch - the box around the slider */
.app-checkbox-switch {
  position: relative;
  display: block;
  width: 4rem;
  height: 2.4rem;
  flex-shrink: 0;
  flex-grow: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($colors, 'inputBorder');
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  &-slider:before {
    position: absolute;
    content: '';
    height: 2rem;
    width: 2rem;
    left: 0.2rem;
    bottom: 0.2rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:disabled ~ &-slider {
    opacity: 0.5;
  }

  input:checked + &-slider {
    background-color: map-get($colors, 'primary');
  }

  input:focus + &-slider {
    box-shadow: 0 0 1px map-get($colors, 'primary');
  }

  input:checked + &-slider:before {
    -webkit-transform: translateX(1.6rem);
    -ms-transform: translateX(1.6rem);
    transform: translateX(1.6rem);
  }
}

.app-checkbox-switch-label {
  align-items: center;
  display: flex;

  @include media-breakpoint-up(2k) {
    font-size: 1.7rem;
  }
}

@include media-breakpoint-up(md) {
  .app-checkbox-switch-md-reverse {
    .app-checkbox-switch-container {
      flex-direction: row-reverse;
    }
  }
}
