/* Google autocomplete for address search bar */
.pac-container {
  border: none;
  @include app-border-radius(20px);
  @include app-box-shadow;
  font-family: $app-font-secondary;
  margin-left: -2rem;
  margin-top: 1.4rem;
  padding: 1rem 0;

  .pac-item,
  .pac-item-query {
    border: none;
    font-size: 1.4rem;
  }

  .pac-item {
    padding: 0.5rem 2rem;
  }

  .pac-item-selected {
    background-color: map-get($colors, 'magicMint');
  }

  .pac-icon {
    display: none;
  }

  &:after {
    content: none;
  }

  @include media-breakpoint-up(md) {
    @include app-border-radius(35px);
    margin-left: -3rem;
    margin-top: 2.5rem;
    padding: 2rem 0 2.5rem;

    .pac-item,
    .pac-item-query {
      font-size: 1.6rem;
    }

    .pac-item {
      padding: 1rem 3rem;
    }
  }

  @include media-breakpoint-up(fullHD) {
    margin-top: 3.2rem;
  }
}
