.app-input {
  margin-bottom: unset;

  &-label {
    margin-bottom: 1.2rem;
  }
}

@include media-breakpoint-up(xxl) {
  .app-input {
    margin-bottom: unset;
  }

  .app-input-label {
    margin-bottom: 1.4rem;
  }
}

.app-field-container {
  background-color: #f7f9fc; /* TODO variable */
  border: 1px solid #8c93a5; /* TODO variable */
}

.app-field {
  background-color: #f7f9fc; /* TODO variable */
}

.app-datetime {
  background-color: #f7f9fc; /* TODO variable */
  border: 1px solid #8c93a5; /* TODO variable */
}

.app-input-readonly {
  .app-field-container {
    border: none;
  }

  .app-datetime {
    border: none;
  }
}
